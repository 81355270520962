:root {
    
    --primary-color: rgb(0, 255, 149);
    --primary-color-light: rgb(129, 255, 149);
    --secondary-color: rgb(0, 0, 0);

    --font-size: 22px;
    --font-size-mini: 19px;
    --font-weight: bold;

}

h1 {
    line-height: 1em;
    margin: 3px;
}

.chess-container {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: var(--font-size);
}

.mainScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px;
}

.exit-button {    
}

.chessboard {
    margin-top:40px;
}

.card {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    width: 650px;
    min-height: 64px;
}

.button {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    height: 50px;
    width: 200px;    
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size);
    font-weight: bold;
    cursor: pointer;
}

.buttonmini {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-color-light);
    color: var(--secondary-color);
    height: 40px;
    width: 200px;    
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-mini);
    font-weight: bold;
    cursor: pointer;
    margin-top:1px;
}

.buttonselected {
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
    height: 60px;
    width: 240px;    
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size);
    font-weight: bold;
    cursor: pointer;
    background-color: var(--secondary-color);
}

.chess-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    margin: 10px;
}

.chessButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.chesscomments {
    display: flex;    
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1em;
    font-weight: var(--font-weight);
    color: rgb(255, 0, 0);
}

.chessmenu {
    display: flex;
    width: 50%;
    height: 50%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;    
    justify-content: center;
    margin: 10px;
    top: 0;
    right: 10px;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: rgb(255, 255, 255);
    font-variant: small-caps;
    margin: auto;
    overflow: auto;
}

.topchessmenu {
    display: flex;
    top: 0;
    right: 10px;
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    color: rgb(255, 255, 255);
    justify-content: space-evenly;
    gap: 52px;
}

.avatar {
    background-color: rgb(0,0,0, 0.2);
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin: 10px;
    width: 200px;
    height: 60px;
    padding-right: 10px;
}

.avatar.selected {
    background-color: rgba(190, 135, 135, 0.5);
}

.avataricon {
    height: 60px;
}

.gameavatar {        
    font-variant: small-caps;
    position: absolute;
    top: 30px;
    right: 0;
}

.avatarinfo {
    width: 100%;    
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    gap: 5px;
}

.hContainer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;  
    gap: 5px;
    margin-top: 1px;
}


@media (max-width: 600px) {
    .chessmenu {
        width: 100%;
    }
    .topchessmenu {        
    }

    .chess-container {
        margin:2px;
    }

    .chessboard {
        margin-top:20px;
    }

    .gameavatar {
        position: relative;
        top:0;
        right: 1px;
        margin-bottom: 20px;        
        width: 80%;
    }

    .avatar {
        width: 90%;
        margin: 2px;
        font-size: 16px;
        height: 40px;
    }

    .avataricon {
        height: 40px;
    }

    .button {
        width: 120px;
    }

    .buttonselected {
        width: 140px;
    }

    .card {
        width: 100%;
        min-height:100px;
        font-size: 16px;
        font-weight: normal;
        border-radius: 10px;
        vertical-align: middle;
        text-align: center;        
        align-content: center;
    }
    .hContainer {
        flex-direction: column;
        height: unset;
    }
}